import FuseLoading from "@fuse/core/FuseLoading"
import FusePageCarded from "@fuse/core/FusePageCarded"
import { useDeepCompareEffect } from "@fuse/hooks"
import Button from "@mui/material/Button"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import React, { SyntheticEvent, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import _ from "@lodash"
import { FormProvider, useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import ProductHeader from "./FormHeader"
import TextField from "@mui/material/TextField"
import { FormControl, InputLabel, MenuItem } from "@mui/material"
import { Autocomplete, Checkbox } from "@mui/material"
import { ApiServices } from "src/services/ApiServices"
import { StudyLevelTypes } from "../../studylevels/types/StudyLevelTypes"

type FormValues = {
  name: string
  studylevelid: string[]
}

function SpecialitieForm() {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))

  const routeParams = useParams()
  const [tabValue, setTabValue] = useState(0)
  const [noProduct, setNoProduct] = useState(false)
  const [studyLevels, setStudyLevels] = useState<StudyLevelTypes[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ApiServices.getAllStudyLevels()
      .then((res) => {
        setStudyLevels(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const methods = useForm<FormValues>({
    mode: "onChange"
  })

  const { control, formState, register, setValue } = methods

  useDeepCompareEffect(() => {
    function updateProductState() {
      const { id } = routeParams

      if (id !== "new") {
        setLoading(true)
        ApiServices.getSpecialitieBtId(id)
          .then((res) => {
            setLoading(false)
            setValue("name", res.data.data.name)
            setValue("studylevelid", res.data.data.studyLevelID || [])
          })
          .catch((err) => {
            console.log(err)
            setNoProduct(true)
            setLoading(false)
          })
      }
    }

    updateProductState()
  }, [routeParams])

  function handleTabChange(event: SyntheticEvent, value: number) {
    setTabValue(value)
  }

  if (noProduct) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-col flex-1 items-center justify-center h-full"
      >
        <Typography color="text.secondary" variant="h5">
          There is no such product!
        </Typography>
        <Button
          className="mt-24"
          component={Link}
          variant="outlined"
          to="/apps/e-commerce/products"
          color="inherit"
        >
          Go to Products Page
        </Button>
      </motion.div>
    )
  }

  if (loading) {
    return <FuseLoading />
  }

  return (
    <FormProvider {...methods}>
      <FusePageCarded
        header={<ProductHeader />}
        content={
          <>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{ root: "w-full h-64 border-b-1" }}
            >
              <Tab className="h-64" label="Basic Info" />
            </Tabs>
            <div className="p-16 sm:p-24 max-w-3xl">
              <div className={tabValue !== 0 ? "hidden" : ""}>
                <div>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mt-8 mb-16"
                        required
                        label="Name"
                        autoFocus
                        id="name"
                        variant="outlined"
                        fullWidth
                        {...register("name", {
                          required: {
                            value: true,
                            message: "name is required"
                          }
                        })}
                        error={!!formState.errors.name}
                        helperText={formState.errors?.name?.message as string}
                        InputLabelProps={{
                          shrink: !!field.value
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="studylevelid"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="mt-8 mb-16"
                      >
                        <Autocomplete
                          multiple
                          options={studyLevels}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          value={studyLevels.filter((option) =>
                            field.value.includes(option.id)
                          )}
                          onChange={(event, newValue) => {
                            const idsArray = newValue.map((option) => option.id)
                            field.onChange(idsArray)
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Study Level"
                              placeholder="Select Study Levels"
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        }
        scroll={isMobile ? "normal" : "content"}
      />
    </FormProvider>
  )
}

export default SpecialitieForm
