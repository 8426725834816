import Button from "@mui/material/Button"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"
import FaqListStudent from "./FaqListStudent"

/**
 * The help center faqs page with static questions and categories.
 */
function FaqPageStudent() {
  const navigate = useNavigate()

  // Données statiques pour les FAQ et catégories
  const categories = [
    {
      id: "etudiants-technique",
      title: "Questions Techniques",
      faqs: [
        {
          id: "9",
          categoryId: "etudiants-technique",
          question: "Comment rejoindre un meeting en ligne ?",
          answer:
            "Pour rejoindre un meeting, accédez à la section 'Mes Cours', sélectionnez le cours concerné, puis allez dans l'onglet 'Groupes'. Trouvez votre groupe et cliquez sur 'Rejoindre la réunion' à côté du nom du groupe à l'heure prévue. Vous serez redirigé vers le lien de la réunion fourni par votre professeur."
        },
        {
          id: "10",
          categoryId: "etudiants-technique",
          question:
            "Puis-je accéder aux enregistrements des sessions passées ?",
          answer:
            "Oui, si le professeur a partagé les enregistrements. Ils sont généralement disponibles peu après la fin de chaque session."
        },
        {
          id: "16",
          categoryId: "etudiants-technique",
          question: "Comment renouveler mon abonnement ?",
          answer:
            "Pour renouveler votre abonnement, allez dans le menu 'Mes Paiements', sélectionnez l'abonnement expiré, puis téléchargez le justificatif de votre paiement. Nous vérifierons votre paiement et réactiverons l'accès à votre cours une fois la confirmation effectuée."
        }
      ]
    },
    {
      id: "etudiants-signalement",
      title: "Annulation et Signalement",
      faqs: [
        {
          id: "12",
          categoryId: "etudiants-signalement",
          question: "Comment annuler mon abonnement à un cours ?",
          answer:
            "Pour annuler votre abonnement, allez dans 'Mes cours' dans votre profil, sélectionnez le cours concerné, puis cliquez sur 'Annuler l'abonnement'. Notez que certaines conditions d'annulation peuvent s'appliquer, selon les règles de chaque cours."
        },
        {
          id: "13",
          categoryId: "etudiants-signalement",
          question: "Puis-je obtenir un remboursement si j'annule un cours ?",
          answer:
            "Les remboursements dépendent de la politique de remboursement établie par chaque cours. Consultez les conditions d'annulation dans 'Détails du cours' ou contactez notre support pour plus d'informations."
        },
        {
          id: "14",
          categoryId: "etudiants-signalement",
          question:
            "Comment signaler un problème technique pendant un meeting ?",
          answer:
            "En cas de problème technique pendant un meeting, essayez de rafraîchir la page ou de rejoindre la réunion. Si le problème persiste, contactez notre support technique via la section 'Aide'."
        },
        {
          id: "15",
          categoryId: "etudiants-signalement",
          question:
            "Comment puis-je signaler un comportement inapproprié de la part d'un professeur ?",
          answer:
            "Si vous remarquez un comportement inapproprié, allez dans la section 'Signaler' du cours concerné et décrivez le problème. Notre équipe examinera rapidement votre signalement et prendra les mesures appropriées."
        }
      ]
    }
  ]

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <div className="flex flex-col items-center p-24 sm:p-40">
      <div className="flex flex-col w-full max-w-4xl">
        <div className="sm:mt-32">
          <Button
            onClick={handleGoBack}
            color="secondary"
            startIcon={
              <FuseSvgIcon>heroicons-outline:arrow-narrow-left</FuseSvgIcon>
            }
          >
            Back to Help Center
          </Button>
        </div>
        <Typography className="mt-8 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
          Frequently Asked Questions
        </Typography>

        {categories.map((category) => (
          <div key={category.id}>
            <Typography className="mt-48 sm:mt-64 text-3xl font-bold leading-tight tracking-tight">
              {category.title}
            </Typography>
            <FaqListStudent className="w-full mt-32" list={category.faqs} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FaqPageStudent
