import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { Controller, useForm, FormProvider } from "react-hook-form"
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  IconButton,
  Tooltip,
  TableSortLabel,
  FormHelperText
} from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"
// for Modal
import Backdrop from "@mui/material/Backdrop"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Typography from "@mui/material/Typography"

import Box from "@mui/material/Box"
import { lighten } from "@mui/material/styles"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import { useEffect, useState } from "react"
import { motion } from "framer-motion"
import Button from "@mui/material/Button"
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//
import _ from "@lodash"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { ApiServices } from "src/services/ApiServices"
import PackageBadge from "../PackageBadge"
import { useParams } from "react-router-dom"
import { useSnackbar } from "notistack"

//
import NotificationModel from "app/theme-layouts/shared-components/notificationPanel/models/NotificationModel"
import { useAppSelector } from "app/store"
import { selectUser } from "app/store/user/userSlice"
import swal from "sweetalert"
import ModeIcon from "@mui/icons-material/Mode"

interface Column {
  id:
    | "selectedCell"
    | "group_name"
    | "start_end_time"
    | "start_end_date"
    | "recurrence_days"
    | "recurrence"
    | "package_type"
  label: string | React.ReactNode
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
  sort: boolean
}

interface groupsTypes {
  tabValueChange: number
}

//

const GroupsTab: React.FC<groupsTypes> = ({ tabValueChange }) => {
  const courseId = useParams().id
  const { token, id } = useAppSelector(selectUser)

  const [createdGroups, setCreatedGroups] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isNew, setIsNew] = useState<boolean>(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  //
  const [hasSilverId, setHasSilverId] = useState<string>()
  const [hasGoldId, setHasGoldId] = useState<string>()
  const [hasPlatinumId, setHasPlatinumId] = useState<string>()
  //
  const loadSlotsTableData = () => {
    ApiServices.getSlotsByCourseId(token, Number(courseId))
      .then((res) => {
        setCreatedGroups(res.data.data)
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    loadSlotsTableData()
  }, [])

  useEffect(() => {
    const localSilver = localStorage.getItem("silverSubId")
    const localGold = localStorage.getItem("goldSubId")
    const localPlatinum = localStorage.getItem("platinumSubId")
    if (localSilver) {
      setHasSilverId(localSilver)
    }
    if (localGold) {
      setHasGoldId(localGold)
    }
    if (localPlatinum) {
      setHasPlatinumId(localPlatinum)
    }
  }, [tabValueChange === 2])
  //
  const groupsMethods = useForm({
    mode: "onChange"
  })

  const recurrence = ["DAILY", "WEEKLY", "MONTHLY"]

  const recurrenceDays = [
    "DIMANCHE", // SUNDAY
    "LUNDI", // MONDAY
    "MARDI", // TUESDAY
    "MERCREDI", // WEDNESDAY
    "JEUDI", // THURSDAY
    "VENDREDI", // FRIDAY
    "SAMEDI" // SATURDAY
  ]

  const [errMsg, setErrMsg] = useState<string>("")
  const [selectedCell, setSelectedCell] = useState<number>(0)

  const handleDeleteGroup = () => {
    swal({
      title: "Delete",
      text: "Are you sure want to delete permanently",
      icon: "warning",
      buttons: ["No", "Yes"]
    }).then((will) => {
      if (will) {
        ApiServices.removeGroupByTeacherAndGroupId(token, id, selectedCell)
          .then((res) => {
            ApiServices.coursePublishStatus(token, Number(courseId))
            loadSlotsTableData()

            enqueueSnackbar("Group and Slot Deleted Successfuly", {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            })

            setIsNew(true)
            setSelectedCell(0)
            groupsMethods.setValue("id", 0)
            groupsMethods.setValue("subscriptionId", "")
            groupsMethods.setValue("groupName", "")

            groupsMethods.setValue("package", "")

            //  Start Data

            groupsMethods.setValue("startDate", new Date())

            // end Data

            groupsMethods.setValue("endDate", new Date())

            // Start Time

            groupsMethods.setValue("startTime", "")

            // End Time

            groupsMethods.setValue("endTime", "")
            // resumrance
            groupsMethods.setValue("recurrence", "")
            // recurrenceDays
            groupsMethods.setValue("recurrenceDays", "")
          })
          .catch((err) => {
            console.log(err)
            enqueueSnackbar(err.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            })
          })
      }
    })
  }

  //
  const columns: readonly Column[] = [
    { id: "group_name", label: "Nom du groupe", minWidth: 150, sort: true },
    {
      id: "start_end_time",
      label: " Heure début/fin",
      minWidth: 170,
      sort: true
    },
    {
      id: "start_end_date",
      label: "Date de fin",
      minWidth: 170,
      sort: true
    },
    {
      id: "recurrence_days",
      label: "Jours de récurrence",
      minWidth: 170,
      sort: true
    },
    { id: "package_type", label: "Abonnement", minWidth: 80, sort: true },
    {
      id: "selectedCell",
      label: (
        <div className="flex items-center justify-center">
          <IconButton
            aria-haspopup="true"
            onClick={handleDeleteGroup}
            size="large"
            disabled={selectedCell === 0}
          >
            <FuseSvgIcon>heroicons-outline:trash</FuseSvgIcon>
          </IconButton>
          {/*  */}
          <IconButton
            aria-haspopup="true"
            onClick={() => handleModalOpen()}
            size="large"
            disabled={selectedCell === 0}
          >
            <ModeIcon fontSize="medium" />
          </IconButton>
        </div>
      ),
      minWidth: 20,
      sort: false
    }
  ]

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // Time Formate
  function formatTime(timeArray) {
    if (!timeArray || timeArray.length !== 2) return ""

    const [hour, minute] = timeArray

    const date = new Date()
    date.setHours(hour)
    date.setMinutes(minute)

    return date.toLocaleTimeString([], {
      hour12: false, // Set to false to use 24-hour format
      hour: "2-digit",
      minute: "2-digit"
    })
  }

  function formatDate(dateArray) {
    if (!dateArray || dateArray.length !== 3) return ""

    const [year, month, day] = dateArray

    // Note: JavaScript months are 0-indexed, so we need to subtract 1 from the month
    const date = new Date(year, month - 1, day)

    // Get the 3-letter abbreviation of the month
    const monthAbbreviation = date.toLocaleDateString(undefined, {
      month: "short"
    })

    // Format the date as "DD/Mon/YYYY"
    return `${day}/${monthAbbreviation}/${year}`
  }

  const [groupSubmit, setGroupSubmit] = useState(false)

  const handleGroupsSlotsSubmit = (data) => {
    let item

    setGroupSubmit(true)

    setErrMsg("")
    let packageId: number | string
    if (isNew) {
      item = NotificationModel({
        title: "Group and Slot added Successfuly",
        variant: "success"
      })
      if (data.package === "PLATINUM") {
        packageId = localStorage.getItem("platinumSubId")
      }
      if (data.package === "SILVER") {
        packageId = localStorage.getItem("silverSubId")
      }
      if (data.package === "GOLD") {
        packageId = localStorage.getItem("goldSubId")
      }
    } else {
      packageId = data.subscriptionId

      item = NotificationModel({ title: "Group and Slot Updated Successfuly" })
    }

    const StoreData = {
      groupName: data.groupName,
      subscriptionId: packageId,
      meetingLink: data.meetingLink,
      slotRequests: [
        {
          slotId: data.slotId,
          startTime: data.startTime,
          endTime: data.endTime,
          startDate: data.startDate,
          endDate: data.endDate,
          recurrence: data.recurrence,
          recurrenceDays: data.recurrenceDays
        }
      ]
    }
    if (isNew) {
      ApiServices.StoreGroupPlusSlot(token, id, StoreData)
        .then((res) => {
          console.log(res)
          if (res.data.status === "SUCCESS") {
            // Mise à jour réussie
            enqueueSnackbar(res.data.details, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            })
            loadSlotsTableData()
            handleModalClose()
          } else {
            setErrMsg(res.data.error)
          }
          setGroupSubmit(false)
        })
        .catch((err) => console.log(err))
    } else {
      ApiServices.updateGroupPlusSlot(token, data.id, StoreData)
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            // Mise à jour réussie
            enqueueSnackbar(res.data.details, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            })
            loadSlotsTableData() // Rafraîchir les données de la table
            handleModalClose() // Fermer le modal
          } else {
            setErrMsg(res.data.error)
          }
          setGroupSubmit(false)
        })
        .catch((err) => {
          console.log(err)
          setGroupSubmit(false)
        })
    }
  }

  const [tableOrder, setTableOrder] = useState<{
    direction: "asc" | "desc"
    id: string
  }>({
    direction: "asc",
    id: ""
  })

  function handleRequestSort(
    event: React.MouseEvent<HTMLSpanElement>,
    property: string
  ) {
    const newOrder: {
      direction: "asc" | "desc"
      id: string
    } = { id: property, direction: "desc" }

    if (tableOrder.id === property && tableOrder.direction === "desc") {
      newOrder.direction = "asc"
    }

    setTableOrder(newOrder)
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    width: "700px",
    margin: "auto",
    padding: "20px 15px"
  }

  const [modalOpen, setModalOpen] = useState(false)
  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  return (
    <div>
      <div className=" w-full ">
        <div className="">
          {/* Modal Form */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={modalOpen}
            onClose={handleModalClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500
              }
            }}
          >
            <Fade in={modalOpen}>
              <Box
                sx={{
                  ...style,
                  width: { xs: "100%", sm: "80%", md: "60%", lg: "700px" },
                  maxWidth: "100%",

                  height: { xs: "100%", sm: "90%" },
                  overflowY: "scroll"
                }}
              >
                <div className="flex items-center justify-between mb-4">
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                    className="mb-4"
                  >
                    Groupes
                  </Typography>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setModalOpen(false)
                      groupsMethods.setValue("id", 0)
                      groupsMethods.setValue("subscriptionId", "")
                      groupsMethods.setValue("groupName", "")

                      groupsMethods.setValue("package", "")

                      //  Start Data

                      groupsMethods.setValue("startDate", new Date())

                      // end Data

                      groupsMethods.setValue("endDate", new Date())

                      // Start Time

                      groupsMethods.setValue("startTime", "")

                      // End Time

                      groupsMethods.setValue("endTime", "")
                      // resumrance
                      groupsMethods.setValue("recurrence", "")
                      // recurrenceDays
                      groupsMethods.setValue("recurrenceDays", "")
                    }}
                  >
                    <ClearIcon />
                  </div>
                </div>
                <FormProvider {...groupsMethods}>
                  <form
                    onSubmit={groupsMethods.handleSubmit(
                      handleGroupsSlotsSubmit
                    )}
                  >
                    <div className="border py-12 px-14 rounded-8 flex flex-col w-full relative">
                      <div className="">
                        {/* id */}
                        <Controller
                          name="id"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required
                              id="id"
                              type="hidden"
                              className="hidden"
                            />
                          )}
                        />
                        {/* subscriptionId */}
                        <Controller
                          name="slotId"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="slotId"
                              type="hidden"
                              className="hidden"
                            />
                          )}
                        />
                        <Controller
                          name="subscriptionId"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required
                              id="subscriptionId"
                              type="hidden"
                              className="hidden"
                            />
                          )}
                        />
                        {/*  */}
                        <Controller
                          name="package"
                          defaultValue={""}
                          render={({ field }) => (
                            <FormControl fullWidth variant="outlined">
                              <InputLabel id="package-type-label">
                                Abonnement
                              </InputLabel>
                              <Select
                                {...field}
                                value={field.value || []}
                                onChange={(e) => field.onChange(e.target.value)}
                                fullWidth
                                className="mt-8 mb-16"
                                variant="outlined"
                                labelId="package-type-label"
                                label="Abonnement"
                              >
                                {hasSilverId && (
                                  <MenuItem value={"SILVER"}>SILVER</MenuItem>
                                )}
                                {hasGoldId && (
                                  <MenuItem value={"GOLD"}>GOLD</MenuItem>
                                )}
                                {hasPlatinumId && (
                                  <MenuItem value={"PLATINUM"}>
                                    PLATINUM
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          )}
                        />
                        {/*  */}
                        <Controller
                          name={`groupName`}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mt-8 mb-16"
                              required
                              label={`Nom du groupe`}
                              name={`group_name`}
                              variant="outlined"
                              type="text"
                              fullWidth
                            />
                          )}
                        />
                        {/* Start Date */}
                        <Controller
                          name={`startDate`}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mt-8 mb-16"
                              required
                              label={`Date de début`}
                              name={`startDate`}
                              variant="outlined"
                              type="date"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                min: new Date().toISOString().split("T")[0] // Date actuelle comme limite minimale
                              }}
                            />
                          )}
                        />
                        {/* End Date */}
                        <Controller
                          name={`endDate`}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mt-8 mb-16"
                              required
                              label={`Date de fin`}
                              name={`endDate`}
                              variant="outlined"
                              type="date"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                min: new Date().toISOString().split("T")[0] // Date actuelle comme limite minimale
                              }}
                            />
                          )}
                        />
                        {/* Start Time */}
                        <Controller
                          name={`startTime`}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mt-8 mb-16"
                              required
                              label={`Heure de début`}
                              name={`startTime`}
                              variant="outlined"
                              type="time"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        {/* End Time */}
                        <Controller
                          name={`endTime`}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mt-8 mb-16"
                              required
                              label={`Heure de fin`}
                              name={`endTime`}
                              variant="outlined"
                              type="time"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        <Controller
                          name={`meetingLink`}
                          defaultValue=""
                          render={({ field }) => (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <TextField
                                {...field}
                                className="mt-8 mb-16"
                                required
                                label={`Lien de réunion`}
                                placeholder="https://meet.google.com/landing"
                                name={`meetingLink`}
                                variant="outlined"
                                type="text"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                              />
                              <Tooltip title="Merci de se connecter avec votre compte Google, puis cliquez sur 'Nouvelle réunion', démarrez une réunion instantanée, copiez le lien et collez-le ici.">
                                <IconButton>
                                  <FuseSvgIcon>
                                    heroicons-outline:information-circle
                                  </FuseSvgIcon>
                                </IconButton>
                              </Tooltip>
                              {/* Bouton pour rediriger vers Google Meet */}
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: "10px" }}
                                onClick={() =>
                                  window.open(
                                    "https://meet.google.com/landing",
                                    "_blank"
                                  )
                                }
                              >
                                Générer
                              </Button>
                            </div>
                          )}
                        />
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={
                            !!groupsMethods.formState.errors.recurrenceDays
                          }
                        >
                          <Controller
                            name="recurrenceDays"
                            control={groupsMethods.control}
                            defaultValue={[]} // Default value should be an empty array
                            render={({ field }) => {
                              // Ensure field.value is defined and is an array
                              const selectedDays = Array.isArray(field.value)
                                ? field.value
                                : []

                              return (
                                <Autocomplete
                                  multiple
                                  options={recurrenceDays}
                                  disableCloseOnSelect
                                  className="mt-8 mb-16"
                                  getOptionLabel={(option) => option || ""}
                                  value={selectedDays || []}
                                  onChange={(event, newValue) => {
                                    // Pass the selected values directly to field.onChange
                                    field.onChange(newValue)
                                  }}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props} key={option}>
                                      <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Recurrence Days (optional)"
                                      placeholder="Select Days"
                                      error={
                                        !!groupsMethods.formState.errors
                                          .recurrenceDays
                                      }
                                    />
                                  )}
                                />
                              )
                            }}
                          />
                          {groupsMethods.formState.errors.recurrenceDays && (
                            <FormHelperText error>
                              Recurrence days are required
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                      <div>
                        <span className="text-red-500 text-base">{errMsg}</span>
                      </div>
                      {/*  */}
                      <div className="flex justify-end">
                        <motion.div
                          initial={{ opacity: 0, x: 20 }}
                          animate={{
                            opacity: 1,
                            x: 0,
                            transition: { delay: 0.2 }
                          }}
                          className="w-full"
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className="px-16 py-2"
                            fullWidth
                            disabled={groupSubmit}
                          >
                            {groupSubmit ? "Submiting..." : "Valider"}
                          </Button>
                        </motion.div>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </Box>
            </Fade>
          </Modal>
          {/* Modal Form End */}
          {/*  */}
          {/*  */}
          <div>
            <div className="flex justify-between items-center pb-12">
              <p>Sélectionnez le groupe à modifier</p>

              <div className="px-8">
                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  animate={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.2 }
                  }}
                  className="flex justify-end"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className="px-16 py-2"
                    fullWidth
                    onClick={() => {
                      handleModalOpen()
                      setIsNew(true)
                      setSelectedCell(0)
                      groupsMethods.setValue("id", 0)
                      groupsMethods.setValue("subscriptionId", "")
                      groupsMethods.setValue("groupName", "")

                      groupsMethods.setValue("package", "")

                      //  Start Data

                      groupsMethods.setValue("startDate", new Date())

                      // end Data

                      groupsMethods.setValue("endDate", new Date())

                      // Start Time

                      groupsMethods.setValue("startTime", "")

                      // End Time

                      groupsMethods.setValue("endTime", "")
                      // resumrance
                      groupsMethods.setValue("recurrence", "")
                      // recurrenceDays
                      groupsMethods.setValue("recurrenceDays", "")
                    }}
                  >
                    Nouveau groupe
                  </Button>
                </motion.div>
              </div>
            </div>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "light"
                                ? lighten(theme.palette.background.default, 0.4)
                                : lighten(
                                    theme.palette.background.default,
                                    0.02
                                  )
                          }}
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.sort ? (
                            <Tooltip
                              title="sort"
                              placement={
                                column.align === "right"
                                  ? "bottom-end"
                                  : "bottom-start"
                              }
                              enterDelay={3000}
                            >
                              <TableSortLabel
                                active={tableOrder.id === column.id}
                                direction={tableOrder.direction}
                                onClick={(
                                  ev: React.MouseEvent<HTMLSpanElement>
                                ) => handleRequestSort(ev, column.id)}
                                className="font-semibold"
                              >
                                {column.label}
                              </TableSortLabel>
                            </Tooltip>
                          ) : (
                            <>{column.label}</>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.orderBy(
                      createdGroups,
                      [
                        (o) => {
                          switch (o.id) {
                            case "categories": {
                              return o.title[0]
                            }
                            default: {
                              return o.id
                            }
                          }
                        }
                      ],
                      tableOrder.direction === "asc" ? "asc" : "desc"
                    )
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">
                              {
                                row.slots.length > 0
                                  ? `${formatTime(
                                      row.slots[0]?.startTime
                                    )} - ${formatTime(row.slots[0]?.endTime)}`
                                  : "No slots available" // Or any other message you want to display
                              }
                            </TableCell>
                            <TableCell align="left">
                              {
                                row?.slots.length > 0
                                  ? `${formatDate(row.slots[0]?.endDate)}`
                                  : "No Dates available" // Or any other message you want to display
                              }
                            </TableCell>
                            <TableCell align="left">
                              {row?.slots.length > 0
                                ? row.slots[0].recurrenceDaysInFrench.length > 0
                                  ? row.slots[0].recurrenceDaysInFrench.join(
                                      ", "
                                    )
                                  : row.slots[0].recurrenceDaysInFrench[0]
                                : "pas de jours de récurrence"}
                            </TableCell>

                            <TableCell align="left">
                              <PackageBadge name={row.subscription.type} />
                            </TableCell>

                            <TableCell align="center">
                              <Checkbox
                                checked={
                                  selectedCell !== 0 && selectedCell === row.id
                                }
                                onChange={() => {
                                  setSelectedCell(row.id)
                                  setIsNew(false)
                                  groupsMethods.setValue("id", row.id)
                                  groupsMethods.setValue(
                                    "subscriptionId",
                                    row.subscriptionId
                                  )
                                  groupsMethods.setValue("groupName", row.name)
                                  groupsMethods.setValue(
                                    "slotId",
                                    row.slots[0]?.id
                                  )

                                  groupsMethods.setValue(
                                    "package",
                                    row.subscription.type
                                  )

                                  //  Start Data
                                  const startDateArray = row.slots[0]?.startDate
                                  const startDate = new Date(
                                    startDateArray[0],
                                    startDateArray[1] - 1,
                                    startDateArray[2]
                                  )

                                  const Syear = startDate.getFullYear()
                                  const Smonth = String(
                                    startDate.getMonth() + 1
                                  ).padStart(2, "0")
                                  const Sday = String(
                                    startDate.getDate()
                                  ).padStart(2, "0")
                                  const formattedstartDate = `${Syear}-${Smonth}-${Sday}`

                                  groupsMethods.setValue(
                                    "startDate",
                                    formattedstartDate
                                  )

                                  // end Data
                                  const endDateArray = row.slots[0]?.endDate
                                  const endDate = new Date(
                                    endDateArray[0],
                                    endDateArray[1] - 1,
                                    endDateArray[2]
                                  )

                                  const year = endDate.getFullYear()
                                  const month = String(
                                    endDate.getMonth() + 1
                                  ).padStart(2, "0")
                                  const day = String(
                                    endDate.getDate()
                                  ).padStart(2, "0")
                                  const formattedEndDate = `${year}-${month}-${day}`

                                  groupsMethods.setValue(
                                    "endDate",
                                    formattedEndDate
                                  )

                                  // Start Time

                                  const startTimeArray = row.slots[0]?.startTime

                                  const hours = startTimeArray[0]
                                  const minutes = startTimeArray[1]

                                  const formattedStartTime = `${String(
                                    hours
                                  ).padStart(2, "0")}:${String(
                                    minutes
                                  ).padStart(2, "0")}`

                                  groupsMethods.setValue(
                                    "startTime",
                                    formattedStartTime
                                  )

                                  // End Time
                                  const endTimeArray = row.slots[0]?.endTime

                                  const endHours = endTimeArray[0]
                                  const endMinutes = endTimeArray[1]

                                  const formattedEndTime = `${String(
                                    endHours
                                  ).padStart(2, "0")}:${String(
                                    endMinutes
                                  ).padStart(2, "0")}`

                                  groupsMethods.setValue(
                                    "endTime",
                                    formattedEndTime
                                  )
                                  // resumrance
                                  groupsMethods.setValue(
                                    "recurrence",
                                    row.slots[0]?.recurrence
                                  )
                                  // recurrenceDays
                                  groupsMethods.setValue(
                                    "recurrenceDays",
                                    row.slots[0]?.recurrenceDays
                                  )
                                  groupsMethods.setValue(
                                    "meetingLink",
                                    row.meetingLink
                                  )
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={createdGroups?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
          {/*  */}
          {/*  */}
        </div>
      </div>
    </div>
  )
}

export default GroupsTab
