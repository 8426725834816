import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import _ from "@lodash"
import Button from "@mui/material/Button"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import { useAppSelector } from "app/store"
import { selectUser } from "app/store/user/userSlice"
import WarningIcon from "@mui/icons-material/Warning"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

/**
 * The ProjectDashboardAppHeader page.
 */
function ProjectDashboardAppHeader() {
  const user = useAppSelector(selectUser)
  const { t } = useTranslation()

  return (
    <div className="relative">
      {/* red */}
      {user?.paymentStatus === "FAILED" && (
        <div className="bg-red-500  top-0 w-full left-0 pl-8 py-4 text-white">
          <div className="flex items-start sm:items-center">
            <WarningIcon fontSize="small" className="mr-2" />
            <div>
              Your Payment Status has some issues. Click{" "}
              <Link to={"/payment-renew"}>
                <span className="underline cursor-pointer font-bold text-white">
                  here
                </span>
              </Link>{" "}
              to resolve it!
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col w-full px-24 sm:px-32 relative">
        <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-32 sm:my-48">
          <div className="flex flex-auto items-center min-w-0">
            {user?.photos.length > 0 ? (
              <Avatar
                className="flex-0 w-64 h-64"
                alt="user photo"
                src={`${process.env.REACT_APP_GLOBAL_IMAGE}${user?.photos[0]?.s3Url}`}
              />
            ) : (
              <Avatar className="md:mx-4">{user?.firstName.charAt(0)}</Avatar>
            )}

            <div className="flex flex-col min-w-0 mx-16">
              <Typography className="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">
                {`Bonjour  ${user?.firstName} 👋  `}
              </Typography>

              <div className="flex items-center">
                {/* <FuseSvgIcon size={20} color="action">
                  heroicons-solid:bell
                </FuseSvgIcon> */}
                {/* <Typography
                  className=" leading-6 truncate"
                  color="text.secondary"
                > */}
                {user?.paymentStatus === "FAILED" && (
                  <div className="text-sm top-0 w-full left-0 py-4 text-red-500">
                    <div className="flex items-start sm:items-center">
                      <WarningIcon fontSize="small" className="mr-2" />
                      <div>
                        Your Payment Status has some issues. Click{" "}
                        <Link to={"/payment-renew"}>
                          <span className="underline cursor-pointer font-bold text-red-500">
                            here
                          </span>
                        </Link>{" "}
                        to resolve it!
                      </div>
                    </div>
                  </div>
                )}
                {/* </Typography> */}
              </div>
            </div>
          </div>
          <div className="flex items-center mt-24 sm:mt-0 sm:mx-8 space-x-12">
            <Button
              className="whitespace-nowrap"
              variant="contained"
              color="secondary"
              href="/profile"
              startIcon={
                <FuseSvgIcon size={20}>heroicons-solid:cog</FuseSvgIcon>
              }
            >
              {t("edit_profile")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDashboardAppHeader
