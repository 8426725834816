import Button from "@mui/material/Button"
import { useNavigate, useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import FuseLoading from "@fuse/core/FuseLoading"
import _ from "@lodash"
import * as yup from "yup"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import Box from "@mui/system/Box"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Avatar from "@mui/material/Avatar"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import Autocomplete from "@mui/material/Autocomplete/Autocomplete"
import Checkbox from "@mui/material/Checkbox/Checkbox"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { useAppDispatch, useAppSelector } from "app/store"
import history from "@history"
import { ApiServices } from "src/services/ApiServices"
import { selectUser } from "app/store/user/userSlice"
import { TeacherProfileTypes } from "./types"
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material"

import NotificationTemplate from "app/theme-layouts/shared-components/notificationPanel/NotificationTemplate"
import NotificationModel from "app/theme-layouts/shared-components/notificationPanel/models/NotificationModel"
import { useSnackbar } from "notistack"

// import {
//   addContact,
//   getContact,
//   newContact,
//   removeContact,
//   selectContact,
//   updateContact,
// } from "../store/contactSlice";
// import { selectTags } from "../store/tagsSlice";
// import ContactEmailSelector from "./email-selector/ContactEmailSelector";
// import PhoneNumberSelector from "./phone-number-selector/PhoneNumberSelector";
// import { ContactType } from "../types/ContactType";
// import { TagsType } from "../types/TagType";
// import { ContactEmailType, ContactEmailsType } from "../types/ContactEmailType";
// import {
//   ContactPhoneNumbersType,
//   ContactPhoneNumberType,
// } from "../types/ContactPhoneNumberType";

function BirtdayIcon() {
  return <FuseSvgIcon size={20}>heroicons-solid:cake</FuseSvgIcon>
}

// type FormType = Omit<ContactType, "emails" | "phoneNumbers"> & {
//   emails: Partial<ContactEmailType>[];
//   phoneNumbers: Partial<ContactPhoneNumberType>[];
// };

/**
 * Form Validation Schema
 */
const contactEmailSchema = yup.object().shape({
  email: yup.string(),
  label: yup.string()
})

const contactPhoneNumberSchema = yup.object().shape({
  country: yup.string().required("You must select a country"),
  phoneNumber: yup.string().required("You must enter a phone number"),
  label: yup.string().required("You must enter a label")
})

const schema = yup.object().shape({
  id: yup.string().required("ID is required"),
  avatar: yup.string().required("Avatar is required"),
  background: yup.string().required("Background is required"),
  name: yup.string().required("Name is required"),
  emails: yup.array().of(contactEmailSchema),
  phoneNumbers: yup.array().of(contactPhoneNumberSchema),
  title: yup.string().required("Title is required"),
  company: yup.string().required("Company is required"),
  birthday: yup.string().required("Birthday is required"), // consider using date() if the format is ISO date string
  address: yup.string().required("Address is required"),
  notes: yup.string().required("Notes are required"),
  tags: yup.array().of(yup.string()).required("Tags are required")
})

// Types
interface ProfileEditFormTypes {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
  phone: number
  address: string
  city: string
  photos: string
  gender: string
  birthDate: string
  aboutMe: string
  userImage: File
}

interface ProfileEditTypes {
  handleEdit: () => void

  userData: TeacherProfileTypes
  loadData: () => void
}

/**
 * The contact form.
 */
const ChangeProfile: React.FC<ProfileEditTypes> = ({
  handleEdit,

  userData,
  loadData
}) => {
  const ProfileUpdateMethod = useForm<ProfileEditFormTypes>({
    mode: "onChange"
  })

  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
    handleSubmit
  } = ProfileUpdateMethod

  const { token, id } = useAppSelector(selectUser)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    setValue("firstName", userData?.firstName)
    setValue("lastName", userData?.lastName)
    setValue("email", userData?.email)
    setValue("phone", Number(userData?.phone))
    setValue("address", userData?.address)
    setValue("city", userData?.city)
    // Birth Date
    const bday = new Date(userData?.birthDate)
    const year = bday.getFullYear()
    const month = String(bday.getMonth() + 1).padStart(2, "0")
    const day = String(bday.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    setValue("birthDate", formattedDate)
    setValue("gender", userData?.gender)
    setValue("aboutMe", userData?.aboutMe)
    setValue(
      "photos",
      `${process.env.REACT_APP_GLOBAL_IMAGE}${userData?.photos[0]?.s3Url}`
    )
  }, [])

  const onSubmit = (data) => {
    setIsUpdating(true)
    ApiServices.updateTeacherById(token, id, data)
      .then((res) => {
        setIsUpdating(false)
        handleEdit()
        loadData()

        enqueueSnackbar(res.data.details, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          }
        })
      })
      .catch((err) => {
        setIsUpdating(false)
        enqueueSnackbar(err.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          }
        })
        console.log(err)
      })
  }

  return (
    <>
      <input {...register("userImage")} type="file" className="hidden" />
      <Box
        className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.default"
        }}
      >
        {userData?.backgroundPhoto && (
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src={userData.backgroundPhoto}
            alt="user background"
          />
        )}
      </Box>

      <div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
        <div className="w-full max-w-3xl">
          {/* Profile Image */}
          <div className="w-full">
            <div className="flex flex-auto items-end -mt-64">
              <Controller
                control={control}
                name="photos"
                render={({ field: { onChange, value } }) => (
                  <Box
                    sx={{
                      borderWidth: 4,
                      borderStyle: "solid",
                      borderColor: "background.paper"
                    }}
                    className="relative flex items-center justify-center w-128 h-128 rounded-full overflow-hidden"
                  >
                    <div className="absolute inset-0 bg-black bg-opacity-50 z-10" />
                    <div className="absolute inset-0 flex items-center justify-center z-20">
                      <div>
                        <label
                          htmlFor="button-avatar"
                          className="flex p-8 cursor-pointer"
                        >
                          <input
                            accept="image/*"
                            className="hidden"
                            id="button-avatar"
                            type="file"
                            onChange={async (e) => {
                              function readFileAsync() {
                                return new Promise((resolve, reject) => {
                                  const file = e?.target?.files?.[0]
                                  if (!file) {
                                    return
                                  }
                                  setValue("userImage", file)
                                  const reader: FileReader = new FileReader()

                                  reader.onload = () => {
                                    if (typeof reader.result === "string") {
                                      resolve(
                                        `data:${file.type};base64,${btoa(
                                          reader.result
                                        )}`
                                      )
                                    } else {
                                      reject(
                                        new Error(
                                          "File reading did not result in a string."
                                        )
                                      )
                                    }
                                  }

                                  reader.onerror = reject

                                  reader.readAsBinaryString(file)
                                })
                              }

                              const newImage = await readFileAsync()

                              onChange(newImage)
                            }}
                          />
                          <FuseSvgIcon className="text-white">
                            heroicons-outline:camera
                          </FuseSvgIcon>
                        </label>
                      </div>
                      <div>
                        <IconButton
                          onClick={() => {
                            onChange("")
                          }}
                        >
                          <FuseSvgIcon className="text-white">
                            heroicons-solid:trash
                          </FuseSvgIcon>
                        </IconButton>
                      </div>
                    </div>
                    <Avatar
                      sx={{
                        backgroundColor: "background.default",
                        color: "text.secondary"
                      }}
                      className="object-cover w-full h-full text-64 font-bold"
                      src={value}
                      alt={userData.firstName}
                    >
                      {userData?.firstName?.charAt(0)}
                    </Avatar>
                  </Box>
                )}
              />
            </div>
          </div>
          {/* First Name */}
          <Controller
            control={control}
            name="firstName"
            defaultValue=""
            render={({ field }) => (
              <TextField
                className="mt-32"
                {...field}
                label="First Name"
                placeholder="First Name"
                id="firstName"
                {...register("firstName", {
                  required: "First Name is Required"
                })}
                error={!!errors.firstName}
                helperText={errors?.firstName?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
          {/* Last Name */}
          <Controller
            control={control}
            name="lastName"
            defaultValue=""
            render={({ field }) => (
              <TextField
                className="mt-32"
                {...field}
                label="Last Name"
                placeholder="Last Name"
                id="lastName"
                {...register("lastName", { required: "Last Name is Required" })}
                error={!!errors.lastName}
                helperText={errors?.lastName?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
          {/* Email */}
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={({ field }) => (
              <TextField
                className="mt-32"
                {...field}
                label="Email"
                placeholder="Email"
                id="email"
                {...register("email", {
                  required: "Email is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  }
                })}
                error={!!errors.email}
                helperText={errors?.email?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          {/* Phone */}
          <Controller
            control={control}
            name="phone"
            defaultValue={0}
            render={({ field }) => (
              <TextField
                className="mt-32"
                {...field}
                label="Phone"
                placeholder="Phone"
                id="phone"
                type="number"
                {...register("phone", { required: "Phone Number is Required" })}
                error={!!errors.phone}
                helperText={errors?.phone?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
          {/* Address */}
          <Controller
            control={control}
            name="address"
            defaultValue=""
            render={({ field }) => (
              <TextField
                className="mt-32"
                {...field}
                label="Address"
                placeholder="Address"
                id="address"
                {...register("address", { required: "Address is Required" })}
                error={!!errors.address}
                helperText={errors?.address?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
          {/* City */}
          <Controller
            control={control}
            name="city"
            defaultValue=""
            render={({ field }) => (
              <TextField
                className="mt-32"
                {...field}
                label="City Name"
                placeholder="City Name"
                id="city"
                {...register("city", { required: "City Name is Required" })}
                error={!!errors.city}
                helperText={errors?.city?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
          {/* Gender */}
          <Controller
            name="gender"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <FormControl fullWidth variant="outlined" className="mt-32">
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  {...field}
                  value={field.value || []}
                  onChange={(e) => field.onChange(e.target.value)}
                  fullWidth
                  className=""
                  variant="outlined"
                  labelId="gender-label"
                  label="Gender"
                  {...register("gender", {
                    required: {
                      value: true,
                      message: "Please Select Your Gender"
                    }
                  })}
                >
                  <MenuItem value={"MALE"}>Male</MenuItem>
                  <MenuItem value={"FEMALE"}>Female</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {/* Birth Date */}
          <Controller
            name={`birthDate`}
            defaultValue={""}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-32"
                required
                label={`Birth Date`}
                name={`birthDate`}
                variant="outlined"
                type="date"
                fullWidth
                {...register("birthDate", {
                  required: {
                    value: true,
                    message: "Please Select Your Date Of Birth"
                  }
                })}
                error={!!errors.birthDate}
                helperText={errors?.birthDate?.message}
              />
            )}
          />
          {/* About Date */}
          <Controller
            name={`aboutMe`}
            defaultValue={""}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-32"
                required
                label={`About Me`}
                name={`aboutMe`}
                variant="outlined"
                type="text"
                fullWidth
                {...register("aboutMe", {
                  required: {
                    value: true,
                    message: "Please fill About Me"
                  }
                })}
                error={!!errors.birthDate}
                helperText={errors?.birthDate?.message}
              />
            )}
          />

          <Box
            className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t"
            sx={{ backgroundColor: "background.default" }}
          >
            <Button className="ml-auto" onClick={handleEdit}>
              Cancel
            </Button>
            <Button
              className="ml-8"
              variant="contained"
              color="secondary"
              onClick={handleSubmit(onSubmit)}
              disabled={isUpdating}
            >
              Save
            </Button>
          </Box>
        </div>
      </div>
    </>
  )
}

export default ChangeProfile
