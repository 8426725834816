import TextField from "@mui/material/TextField"
import {
  Controller,
  useFormContext,
  useForm,
  FormProvider
} from "react-hook-form"
import { motion } from "framer-motion"
import Button from "@mui/material/Button"
import { useNavigate, useParams } from "react-router-dom"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import { ApiServices } from "src/services/ApiServices"
import { useDeepCompareEffect } from "@fuse/hooks"
//
import { useSnackbar } from "notistack"
import NotificationTemplate from "app/theme-layouts/shared-components/notificationPanel/NotificationTemplate"
import NotificationModel from "app/theme-layouts/shared-components/notificationPanel/models/NotificationModel"
import { useAppSelector } from "app/store"
import { selectUser } from "app/store/user/userSlice"

type platinumTypes = {
  id: number
  maxStudent: number
  price: number
}
type silverTypes = {
  id: number
  maxStudent: number
  price: number
}
type goldTypes = {
  id: number
  maxStudent: number
  price: number
}
// Local Storage Store
function useLocalStorage(key: string, initialValue: boolean | number) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key)
    return storedValue ? JSON.parse(storedValue) : initialValue
  })

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === key) {
        setValue(JSON.parse(event.newValue))
      }
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [key])

  const setStoredValue = (newValue) => {
    setValue(newValue)
    localStorage.setItem(key, JSON.stringify(newValue))
  }

  return [value, setStoredValue]
}

function SubscriptionTab() {
  const { token, id } = useAppSelector(selectUser)

  const Navigate = useNavigate()

  const routeParams = useParams()
  const { id: submitedCourseId } = routeParams

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [platinumIsSubmit, setPlatinumIsSubmit] = useLocalStorage(
    "platinumSubmit",
    false
  )
  const [silverIsSubmit, setSilverIsSubmit] = useLocalStorage(
    "silverSubmit",
    false
  )
  const [goldIsSubmit, setGoldIsSubmit] = useLocalStorage("goldSubmit", false)

  // IsSubmiting
  const [platinumIsSubmiting, setPlatinumIsSubmiting] = useState(false)
  const [silverIsSubmiting, setSilverIsSubmiting] = useState(false)
  const [goldIsSubmiting, setGoldIsSubmiting] = useState(false)

  const [platinumSubId, setPlatinumSubId] = useLocalStorage("platinumSubId", 0)
  const [silverSubId, setSilverSubId] = useLocalStorage("silverSubId", 0)
  const [goldSubId, setGoldSubId] = useLocalStorage("goldSubId", 0)

  //
  const platinumMethods = useForm<platinumTypes>({
    mode: "onChange"
  })
  const silverMethods = useForm<silverTypes>({
    mode: "onChange"
  })
  const goldMethods = useForm<goldTypes>({
    mode: "onChange"
  })

  //
  const [platinumErr, setPlatinumErr] = useState("")
  const handlePlatinumSubmit = (data) => {
    setPlatinumIsSubmiting(true)

    if (data.id) {
      const storeData = {
        maxStudents: data.maxStudent,
        price: data.price
      }

      ApiServices.updateSubscriptionById(token, data.id, storeData)
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            setPlatinumIsSubmiting(false)
            const { id } = routeParams
            ApiServices.coursePublishStatus(token, Number(id))

            enqueueSnackbar(res.data.details, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            })
          }

          if (res.data.status === "FAIL") {
            setPlatinumErr(res.data.error)
          }
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar(err.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          })
          setPlatinumIsSubmiting(false)
        })
    } else {
      const storeData = {
        type: "PLATINUM",
        maxStudents: data.maxStudent,
        courseId: submitedCourseId,
        price: data.price
      }

      ApiServices.storeSubscription(token, storeData)
        .then((res) => {
          setPlatinumIsSubmiting(false)
          if (res.data.status === "SUCCESS") {
            setPlatinumSubId(res.data.data.id)
            setPlatinumIsSubmiting(false)
            ApiServices.coursePublishStatus(token, Number(submitedCourseId))
            //

            enqueueSnackbar(res.data.details, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            })
          }

          if (res.data.status === "FAIL") {
            setPlatinumErr(res.data.error)
          }
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar(err.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          })
          setPlatinumIsSubmiting(false)
        })
    }
  }
  //
  const [silverErr, setSilverErr] = useState("")
  const handleSilverSubmit = (data) => {
    setSilverIsSubmiting(true)

    if (data.id) {
      const storeData = {
        maxStudents: data.maxStudent,
        price: data.price
      }
      ApiServices.updateSubscriptionById(token, data.id, storeData)
        .then((res) => {
          setSilverIsSubmiting(false)
          if (res.data.status === "SUCCESS") {
            const { id } = routeParams
            ApiServices.coursePublishStatus(token, Number(id))

            enqueueSnackbar(res.data.details, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            })
          }

          if (res.data.status === "FAIL") {
            setSilverErr(res.data.error)
          }
        })
        .catch((err) => {
          setSilverIsSubmiting(false)
          enqueueSnackbar(err.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          })
          console.log(err)
        })
    } else {
      const storeData = {
        type: "SILVER",
        maxStudents: data.maxStudent,
        courseId: submitedCourseId,
        price: data.price
      }

      ApiServices.storeSubscription(token, storeData)
        .then((res) => {
          setSilverIsSubmiting(false)
          if (res.data.status === "SUCCESS") {
            setSilverSubId(res.data.data.id)
            setSilverIsSubmiting(false)
            ApiServices.coursePublishStatus(token, Number(submitedCourseId))
            //
            enqueueSnackbar(res.data.details, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            })
          }

          if (res.data.status === "FAIL") {
            setSilverErr(res.data.error)
          }
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar(err.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          })
          setSilverIsSubmiting(false)
        })
    }
  }
  //
  const [goldErr, setGoldErr] = useState("")
  const handleGoldSubmit = (data) => {
    setGoldIsSubmiting(true)

    if (data.id) {
      const storeData = {
        maxStudents: data.maxStudent,
        price: data.price
      }
      ApiServices.updateSubscriptionById(token, data.id, storeData)
        .then((res) => {
          setGoldIsSubmiting(false)
          if (res.data.status === "SUCCESS") {
            ApiServices.coursePublishStatus(token, Number(submitedCourseId))

            enqueueSnackbar(res.data.details, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            })
          }

          if (res.data.status === "FAIL") {
            setGoldErr(res.data.error)
          }
        })
        .catch((err) => {
          setGoldIsSubmiting(false)
          enqueueSnackbar(err.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          })
          console.log(err)
        })
    } else {
      const storeData = {
        type: "GOLD",
        maxStudents: data.maxStudent,
        courseId: submitedCourseId,
        price: data.price
      }

      ApiServices.storeSubscription(token, storeData)
        .then((res) => {
          setGoldIsSubmiting(false)
          if (res.data.status === "SUCCESS") {
            setGoldSubId(res.data.data.id)
            setGoldIsSubmiting(false)
            ApiServices.coursePublishStatus(token, Number(submitedCourseId))
            //

            enqueueSnackbar(res.data.details, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            })
          }

          if (res.data.status === "FAIL") {
            setGoldErr(res.data.error)
          }
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar(err.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          })
          setGoldIsSubmiting(false)
        })
    }
  }

  useDeepCompareEffect(() => {
    function updateProductState() {
      const { id } = routeParams

      if (id !== "new") {
        /**
         * Get Study Level By id
         */
        ApiServices.getSubscriptionByCourseId(token, Number(id))
          .then((res) => {
            res.data.data.map((data) => {
              // PLatinum
              if (data.type === "PLATINUM") {
                platinumMethods.setValue("maxStudent", data.maxStudents)
                platinumMethods.setValue("price", data.price)
                platinumMethods.setValue("id", data.id)
                localStorage.setItem("platinumSubId", data.id)
              }
              // Silver
              if (data.type === "SILVER") {
                silverMethods.setValue("maxStudent", data.maxStudents)
                silverMethods.setValue("price", data.price)
                silverMethods.setValue("id", data.id)
                localStorage.setItem("silverSubId", data.id)
              }
              // Gold
              if (data.type === "GOLD") {
                goldMethods.setValue("maxStudent", data.maxStudents)
                goldMethods.setValue("price", data.price)
                goldMethods.setValue("id", data.id)
                localStorage.setItem("goldSubId", data.id)
              }
            })
          })
          .catch((err) => console.log(err))
      }
    }

    updateProductState()
  }, [routeParams])

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 justify-between w-full gap-12">
      <FormProvider {...silverMethods}>
        <form onSubmit={silverMethods.handleSubmit(handleSilverSubmit)}>
          <div className="border py-12 px-14 rounded-8 flex flex-col">
            <h2 className="text-2xl mb-8">Silver</h2>
            {/* Id */}
            <Controller
              name="id"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="id"
                  type="hidden"
                  className="hidden"
                />
              )}
            />

            <Controller
              name="maxStudent"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Max d'etudiants"
                  id="maxStudent"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />

            <Controller
              name="price"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Prix Silver"
                  id="price"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />
            {/*  */}
            <span className="text-red-500 text-sm py-4">{silverErr}</span>
            {/*  */}
            <div className="px-8">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                className="flex justify-end"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="px-16 py-2"
                  fullWidth
                  disabled={silverIsSubmiting}
                >
                  {silverIsSubmiting ? "En cours..." : "Créer"}
                </Button>
              </motion.div>
            </div>
          </div>
        </form>
      </FormProvider>

      <FormProvider {...goldMethods}>
        <form onSubmit={goldMethods.handleSubmit(handleGoldSubmit)}>
          <div className="border py-12 px-14 rounded-8 flex flex-col">
            <h2 className="text-2xl mb-8">Gold</h2>

            {/* Id */}
            <Controller
              name="id"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="id"
                  type="hidden"
                  className="hidden"
                />
              )}
            />

            <Controller
              name="maxStudent"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Max d'etudiants"
                  id="maxStudent"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />

            <Controller
              name="price"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Gold Price"
                  id="price"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />
            {/*  */}
            <span className="text-red-500 text-sm py-4">{goldErr}</span>
            {/*  */}
            <div className="px-8">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                className="flex justify-end"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="px-16 py-2"
                  fullWidth
                  disabled={goldIsSubmiting}
                >
                  {goldIsSubmiting ? "En cours..." : "Créer"}
                </Button>
              </motion.div>
            </div>
          </div>
        </form>
      </FormProvider>

      <FormProvider {...platinumMethods}>
        <form onSubmit={platinumMethods.handleSubmit(handlePlatinumSubmit)}>
          <div className="border py-12 px-14 rounded-8 flex flex-col">
            <h2 className="text-2xl mb-8">Platinum</h2>
            {/* Id */}
            <Controller
              name="id"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="id"
                  type="hidden"
                  className="hidden"
                />
              )}
            />
            {/* max student */}
            <Controller
              name="maxStudent"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Max d'etudiants"
                  id="maxStudent"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />
            {/* platinumPrice */}
            <Controller
              name="price"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  required
                  label="Platinum Price"
                  id="price"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />
            {/*  */}
            <span className="text-red-500 text-sm py-4">{platinumErr}</span>
            {/*  */}
            <div className="px-8">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                className="flex justify-end"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="px-16 py-2"
                  fullWidth
                  disabled={platinumIsSubmiting}
                >
                  {platinumIsSubmiting ? "En cours..." : "Créer"}
                </Button>
              </motion.div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default SubscriptionTab
