import FuseUtils from "@fuse/utils"
import FuseLoading from "@fuse/core/FuseLoading"
import { Navigate, useLocation } from "react-router-dom"
import settingsConfig from "app/configs/settingsConfig"
import { FuseRouteConfigsType, FuseRoutesType } from "@fuse/utils/FuseUtils"
import SignInConfig from "../main/sign-in/SignInConfig"
import SignUpConfig from "../main/sign-up/SignUpConfig"
import SignOutConfig from "../main/sign-out/SignOutConfig"
import Error404Page from "../main/errors/404/Error404Page"
import PagesConfigs from "../main/pages/pagesConfigs"
// import DashboardsConfigs from "../main/dashboards/dashboardsConfigs";
import Subscription from "../main/courses/Courses"
// Subject
import Subjects from "../main/subjects/Subjects"
import SubjectForm from "../main/subjects/form/Form"
// Specialitie Form
import Specialities from "../main/specilaties/Specilaties"
import SpecialitieForm from "../main/specilaties/form/Form"
//
import StudyLevels from "../main/studylevels/StudyLevel"
import StudyLevelForm from "../main/studylevels/form/Form"
import CoursesForm from "../main/courses/form/Form"
import CoursesFormUpdate from "../main/courses/form_update/Form"
// import UserInterfaceConfigs from "../main/user-interface/UserInterfaceConfigs";
// import DocumentationConfig from "../main/documentation/DocumentationConfig";
// import authRoleExamplesConfigs from "../main/auth/authRoleExamplesConfigs";
// Groups
import GroupsListing from "../main/groups/groups/GroupsList"
import SrudentListingInGroup from "../main/groups/students/StudentsList"
import PaymentStatus from "../main/payment-status/Payments"
//
import AdminDashboard from "../main/dashboards/dashboardAdmin/AdminDashboard"
import TeacherDashboard from "../main/dashboards/dashboardTeacher/TeacherDashboard"
import StudentDashboard from "../main/dashboards/dashboardStudent/StudentDashboard"

import DashboardLayout from "../main/dashboards/DashboardLayout"
import ProfileLayout from "../main/profiles/ProfileLayout"
import ResetPassword from "../main/profiles/ResetPassword"
import Lessons from "../main/lessons/lessons/LessonsList"
import Users from "../main/users/Users"
import UsersForm from "../main/users/form/Form"
import PaymentsAdmin from "../main/payment-admin/PaymentsAdmin"
import Refund from "../main/refund/Refund"
import HelpCenterSupport from "../main/help-center/support/HelpCenterSupport"
import Error500Page from "../main/pages/error/Error500Page"
//import FaqList from "../main/help-center/faqs/FaqList"
//import FaqPage from "../main/help-center/faqs/FaqPage"
import PaymentsRenew from "../main/payment-renew/PaymentsRenew"
import PaymentsAdminTeacher from "../main/payment-admin-teacher/PaymentsAdminTeacher"
import FaqPage from "../main/help-center/faq/FaqPage"
import FaqPageStudent from "../main/help-center/faq-student/FaqPageStudent"
import TutorialPage from "../main/help-center/tutorial/TutorialPage"

// Run checkUserRole when the component mounts

const routeConfigs: FuseRouteConfigsType = [
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
  //   DocumentationConfig,
  ...PagesConfigs
  //   ...UserInterfaceConfigs,
  // ...DashboardsConfigs,
  //   ...authRoleExamplesConfigs,
]

/**
 * The routes of the application.
 */

const routes: FuseRoutesType = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),

  {
    path: "/",
    element: <DashboardLayout />, // Render dashboard based on user role
    auth: settingsConfig.defaultAuth
  },

  {
    path: "/profile",
    element: <ProfileLayout />,
    auth: settingsConfig.defaultAuth
  },

  {
    path: "/resetpassword",
    element: <ResetPassword />,
    auth: settingsConfig.defaultAuth
  },

  {
    path: "/admin/dashboard",
    element: <AdminDashboard />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/teacher/dashboard",
    element: <TeacherDashboard />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/student/dashboard",
    element: <StudentDashboard />,
    auth: settingsConfig.defaultAuth
  },

  {
    path: "/courses",
    element: <Subscription />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/course/:id/:path",
    element: <CoursesForm />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/course/:id/edit",
    element: <CoursesFormUpdate />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/subjects",
    element: <Subjects />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/subject/:id",
    element: <SubjectForm />,
    auth: settingsConfig.defaultAuth
  },
  // Users
  {
    path: "/users",
    element: <Users />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/users/:id",
    element: <UsersForm />,
    auth: settingsConfig.defaultAuth
  },
  // Study Levels links
  {
    path: "/study_levels",
    element: <StudyLevels />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/study_level/:id",
    element: <StudyLevelForm />,
    auth: settingsConfig.defaultAuth
  },
  // Specialities Links
  {
    path: "/specialities",
    element: <Specialities />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/specialty/:id",
    element: <SpecialitieForm />,
    auth: settingsConfig.defaultAuth
  },
  // groups
  {
    path: "/students",
    element: <GroupsListing />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/group_students/:groupid",
    element: <SrudentListingInGroup />,
    auth: settingsConfig.defaultAuth
  },
  // Payments Status
  {
    path: "/payment-status",
    element: <PaymentStatus />,
    auth: settingsConfig.defaultAuth
  },
  //
  {
    path: "/payment-admin",
    element: <PaymentsAdmin />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/payment-admin-teacher",
    element: <PaymentsAdminTeacher />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/payment-renew",
    element: <PaymentsRenew />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/refunds",
    element: <Refund />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/support",
    element: <HelpCenterSupport />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/faq",
    element: <FaqPage />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/faq-student",
    element: <FaqPageStudent />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/tutorial-teacher",
    element: <TutorialPage />,
    auth: settingsConfig.defaultAuth
  },
  {
    path: "/lessons",
    element: <Lessons />,
    auth: settingsConfig.defaultAuth
  },
  // Others
  {
    path: "loading",
    element: <FuseLoading />
  },
  {
    path: "404",
    element: <Error404Page />
  },
  {
    path: "500",
    element: <Error500Page />
  },
  {
    path: "*",
    element: <Navigate to="404" />
  }
]

export default routes
