import FuseLoading from "@fuse/core/FuseLoading"
import FusePageCarded from "@fuse/core/FusePageCarded"
import { useDeepCompareEffect } from "@fuse/hooks"
import Button from "@mui/material/Button"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import React, { useState } from "react"
import { useAppSelector } from "app/store"
import { Link, useParams, useNavigate } from "react-router-dom"
import _ from "@lodash"
import { FormProvider, useForm } from "react-hook-form"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import ProductHeader from "./FormHeader"
import CrourseTab from "./tabs/CourseTab"
import SubscriptionTab from "./tabs/SubscriptionTab"
import GroupsTab from "./tabs/GroupsTab"
import { ApiServices } from "src/services/ApiServices"
import Cookies from "js-cookie"
import { selectUser } from "app/store/user/userSlice"

import NotificationTemplate from "app/theme-layouts/shared-components/notificationPanel/NotificationTemplate"
import NotificationModel from "app/theme-layouts/shared-components/notificationPanel/models/NotificationModel"
import { useSnackbar } from "notistack"

type CourseValues = {
  title: string
  description: string
  content: string
  studyLevelId: number
  subjectId: number
  specialtyIds: number[]
  isLanguageCourse: boolean
  photos: File
}

type FormValues = {
  title: string
  description: string
  content: string
  studyLevelId: number
  subjectId: number
  specialtyIds: number[]
  isLanguageCourse: boolean
  photos: File
  // Subscription
  type: string
  maxStudents: number
  platinumPrice: number
}

/**
 * The StudyLevel Form.
 */
function SubscriptionsForm() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))
  const navigate = useNavigate()
  const routeParams = useParams()
  const { path } = routeParams
  const [tabValue, setTabValue] = useState(0)
  const [noProduct, setNoProduct] = useState(false)

  const methodsCourse = useForm<CourseValues>({
    mode: "onChange",
    defaultValues: {
      isLanguageCourse: false // Assurez-vous qu'il a une valeur par défaut
    }
  })

  // const { control, formState, register, setValue } = methods;

  useDeepCompareEffect(() => {
    function updateProductState() {
      if (path === "course") setTabValue(0)
      if (path === "subscription") setTabValue(1)
      if (path === "groups") setTabValue(2)
      if (path === "slots") setTabValue(3)
    }

    updateProductState()
  }, [routeParams])

  /**
   * Show Message if the requested products is not exists
   */
  if (noProduct) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-col flex-1 items-center justify-center h-full"
      >
        <Typography color="text.secondary" variant="h5">
          There is no such product!
        </Typography>
        <Button
          className="mt-24"
          component={Link}
          variant="outlined"
          to="/apps/e-commerce/products"
          color="inherit"
        >
          Go to Products Page
        </Button>
      </motion.div>
    )
  }

  const { token, id } = useAppSelector(selectUser)

  const submitCourse = (data) => {
    setCourseIsSubmiting(true)
    const payload = {
      ...data,
      isLanguageCourse: data.isLanguageCourse === true,
      specialtyIds:
        data.specialtyIds && data.specialtyIds.length > 0
          ? data.specialtyIds
          : []
    }

    ApiServices.storeCourse(token, payload, id)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          navigate("/course/new/subscription")
          setCourseIsSubmiting(false)
          Cookies.set("submitedCourseId", res.data.data.id)
          ApiServices.coursePublishStatus(token, res.data.data.id)

          enqueueSnackbar(res.data.details, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          })
        }
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(err.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          }
        })
        setCourseIsSubmiting(false)
      })
  }

  const [courseIsSubmiting, setCourseIsSubmiting] = useState(false)

  return (
    <>
      <FusePageCarded
        header={<ProductHeader />}
        content={
          <>
            <Tabs
              value={tabValue}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{ root: "w-full h-64 border-b-1" }}
            >
              <Tab className="h-64" label="Course" />
              <Tab className="h-64" label="Abonnement" />
              <Tab className="h-64" label="Groupes" />
            </Tabs>
            <div className="p-16 sm:p-24 w-full">
              <div className={path === "course" ? "" : "hidden"}>
                <FormProvider {...methodsCourse}>
                  <form onSubmit={methodsCourse.handleSubmit(submitCourse)}>
                    <CrourseTab />
                    <motion.div
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                      className="flex justify-end"
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className="px-16 py-2"
                        disabled={courseIsSubmiting}
                      >
                        {courseIsSubmiting ? "Submiting..." : "Valider"}
                      </Button>
                    </motion.div>
                  </form>
                </FormProvider>
              </div>
              {/* Tab 2 */}
              <div className={path === "subscription" ? "" : "hidden"}>
                <SubscriptionTab />
              </div>
              {/* Tab 3 */}
              <div className={path === "groups" ? "" : "hidden"}>
                <GroupsTab />
              </div>
            </div>
          </>
        }
        scroll={isMobile ? "normal" : "content"}
      />
    </>
  )
}

export default SubscriptionsForm
