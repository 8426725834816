import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { Controller, useFormContext, useForm } from "react-hook-form"
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText
} from "@mui/material"
import Box from "@mui/material/Box"
import { lighten, styled } from "@mui/material/styles"
import FuseUtils from "@fuse/utils"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import { ChangeEvent, useEffect, useState } from "react"
import { ApiServices } from "src/services/ApiServices"
import Cookies from "js-cookie"
import { useNavigate } from "react-router"
import Checkbox from "@mui/material/Checkbox"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

/**
 * The basic info tab.
 */
function CourseTab() {
  const {
    control,
    formState: { errors },
    register,
    watch
  } = useFormContext()
  const navigate = useNavigate()
  const isLanguageCourse = watch("isLanguageCourse")

  const [allStudyLevels, setAllStudyLevels] = useState([])
  const [allSubject, setAllSubjects] = useState([])
  const [allSepecialities, setAllSepecialities] = useState([])
  const [filteredSpecialities, setFilteredSpecialities] = useState([])

  useEffect(() => {
    const selectedStudyLevel = watch("studyLevelId")
    console.log("Selected Study Level:", selectedStudyLevel) // Vérifie la valeur sélectionnée
    console.log("All Specialities:", allSepecialities) // Vérifie toutes les spécialités disponibles

    if (selectedStudyLevel) {
      setFilteredSpecialities(
        allSepecialities.filter((speciality) =>
          speciality.studyLevelIds.includes(Number(selectedStudyLevel))
        )
      )
    } else {
      setFilteredSpecialities([])
    }
  }, [watch("studyLevelId"), allSepecialities])

  useEffect(() => {
    // All Study Level
    ApiServices.getAllStudyLevels()
      .then((res) => setAllStudyLevels(res.data.data))
      .catch((err) => console.log(err))
    // All Subjects
    ApiServices.getAllSubjects()
      .then((res) => setAllSubjects(res.data.data))
      .catch((err) => console.log(err))
    // All Specialities
    ApiServices.getAllSepecialities()
      .then((res) => {
        setAllSepecialities(res.data.data)
        console.log(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    let hasCourseId = Cookies.get("submitedCourseId")

    if (hasCourseId) {
      navigate("/course/new/subscription")
    }
  }, [])

  return (
    <div>
      {/* Title */}
      <Controller
        name="title"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            className="mt-8 mb-16"
            required
            label="Titre"
            autoFocus
            id="title"
            variant="outlined"
            fullWidth
            {...register("title", {
              required: {
                value: true,
                message: "Le titre est requis"
              },
              minLength: {
                value: 5,
                message: "Le titre doit contenir au moins 5 caractères"
              }
            })}
            error={!!errors.title}
            helperText={errors?.title?.message as string}
          />
        )}
      />

      {/* Description */}
      <Controller
        name="description"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <TextField
            {...field}
            className="mt-8 mb-16"
            required
            label="Description"
            id="description"
            variant="outlined"
            fullWidth
            multiline
            {...register("description", {
              required: {
                value: true,
                message: "La description est requise"
              },
              minLength: {
                value: 10,
                message: "La description doit contenir au moins 10 caractères"
              },
              maxLength: {
                value: 2500,
                message: "La description ne doit pas dépasser 2500 caractères"
              }
            })}
            minRows={4}
            maxRows={10}
            error={!!errors.description}
            helperText={errors?.description?.message as string}
          />
        )}
      />

      {/* content */}
      <Controller
        name="content"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <TextField
            {...field}
            className="mt-8 mb-16"
            required
            label="Contenu"
            id="content"
            variant="outlined"
            fullWidth
            multiline
            {...register("content", {
              required: {
                value: true,
                message: "Le contenu est requis"
              },
              minLength: {
                value: 10,
                message: "Le contenu doit contenir au moins 10 caractères"
              },
              maxLength: {
                value: 2500,
                message: "Le contenu ne doit pas dépasser 2500 caractères"
              }
            })}
            minRows={4}
            maxRows={10}
            error={!!errors.content}
            helperText={errors?.content?.message as string}
          />
        )}
      />
      {/* Subject */}
      <Controller
        name="subjectId"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <FormControl fullWidth variant="outlined" className="mt-8 mb-16">
            <InputLabel id="subject-label">Matière*</InputLabel>
            <Select
              {...field}
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              fullWidth
              className=""
              variant="outlined"
              labelId="subject-label"
              label="Subject"
              {...register("subjectId", {
                required: {
                  value: true,
                  message: "La matière est requise"
                }
              })}
            >
              {allSubject?.map((level) => (
                <MenuItem key={level.id} value={level.id}>
                  {level.name}
                </MenuItem>
              ))}
            </Select>
            {errors.subjectId && (
              <span className="text-red-500 text-sm">Subject is required</span>
            )}
          </FormControl>
        )}
      />

      {/* isLanguageCourse Checkbox */}
      <Controller
        name="isLanguageCourse"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl className="mt-8 mb-16" fullWidth>
            <label>
              <Checkbox
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
              Cours de langue
            </label>
          </FormControl>
        )}
      />

      {!watch("isLanguageCourse") && (
        <>
          {/* Niveau d'étude */}
          <Controller
            name="studyLevelId"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth variant="outlined" className="mt-8 mb-16">
                <InputLabel id="study-level-label">Niveau d'étude</InputLabel>
                <Select
                  {...field}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  fullWidth
                  labelId="study-level-label"
                  label="Niveau d'étude"
                  {...register("studyLevelId", {
                    required: {
                      value: true,
                      message: "Le niveau d'étude est requis"
                    }
                  })}
                >
                  {allStudyLevels.map((level) => (
                    <MenuItem key={level.id} value={level.id}>
                      {level.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.studyLevelId &&
                  typeof errors.studyLevelId.message === "string" && (
                    <span className="text-red-500 text-sm">
                      {errors.studyLevelId.message}
                    </span>
                  )}
              </FormControl>
            )}
          />

          {/* Filière */}
          <Controller
            name="specialtyIds"
            control={control}
            render={({ field }) => {
              const selectedIds = Array.isArray(field.value) ? field.value : []

              return (
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={filteredSpecialities || []}
                  disableCloseOnSelect
                  className="mt-8 mb-16"
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    filteredSpecialities.filter((option) =>
                      selectedIds.includes(option.id)
                    ) || []
                  }
                  onChange={(event, newValue) => {
                    const idsArray = newValue.map((option) => option.id)
                    field.onChange(idsArray)
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filière (optionnel)"
                      placeholder="Spécialités"
                      error={!!errors.specialtyIds}
                    />
                  )}
                />
              )
            }}
          />
          {errors.specialtyIds &&
            typeof errors.specialtyIds.message === "string" && (
              <FormHelperText error>
                {errors.specialtyIds.message}
              </FormHelperText>
            )}
        </>
      )}
      {/*  */}

      {/*  */}
      <Controller
        name="photos"
        control={control}
        render={({ field }) => (
          // <label htmlFor="course_image" className="w-[100px]">
          <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? lighten(theme.palette.background.default, 0.4)
                  : lighten(theme.palette.background.default, 0.02),
              border: errors.photos ? "2px solid red" : "none",
              position: "relative"
            }}
            component="label"
            htmlFor="button-file"
            className="productImageUpload flex items-center justify-center relative w-128 h-128 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg mt-8"
            onClick={() => document.getElementById("course_image").click()}
          >
            {field.value ? (
              <img
                src={URL.createObjectURL(field.value[0])}
                alt="Course Photo"
              />
            ) : (
              <FuseSvgIcon size={32} color="action">
                heroicons-outline:upload
              </FuseSvgIcon>
            )}
            <input
              name="photos"
              id="course_image"
              type="file"
              className="w-1 h-1"
              accept=".png,.jpeg,.jpg"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e.target.files[0])
              }}
              {...register("photos", {
                required: "Merci de choisir une image"
              })}
            />
          </Box>
          // </label>
        )}
      />

      {errors.photos && (
        <span className="text-red-500 text-sm">Please Select Course Image</span>
      )}

      {/*  */}
    </div>
  )
}

export default CourseTab
