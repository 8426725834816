import Button from "@mui/material/Button"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"
import FaqList from "./FaqList"

/**
 * The help center faqs page with static questions and categories.
 */
function FaqPage() {
  const navigate = useNavigate()

  // Données statiques pour les FAQ et catégories
  const categories = [
    {
      id: "professeurs-technique",
      title: "Questions Techniques",
      faqs: [
        {
          id: "1",
          categoryId: "professeurs-technique",
          question: "Qu'est-ce qu'un abonnement ?",
          answer:
            "Un abonnement est un plan permettant aux étudiants d'accéder aux cours. Dans notre application, il existe trois types d'abonnements : SILVER, GOLD et PLATINUM. Chaque abonnement est défini par un nombre maximal d'étudiants autorisés et un prix, spécifié par le professeur."
        },
        {
          id: "2",
          categoryId: "professeurs-technique",
          question: "Qu'est-ce qu'un groupe ?",
          answer:
            "Pour creer un groupe d'étudiants, il faut le liée à un abonnement spécifique. Pour créer un groupe, vous devez d'abord créer un abonnement et le lier au groupe. Cela permet de gérer les étudiants inscrits en fonction de l'abonnement choisi."
        },
        {
          id: "3",
          categoryId: "professeurs-technique",
          question: "Comment créer un cours ?",
          answer:
            "Pour créer un cours, suivez ces étapes : d'abord, allez dans le menu 'Mes cours' et renseignez les informations de base comme le titre, la description et les ressources. Ensuite, créez un ou plusieurs abonnements (SILVER, GOLD, ou PLATINUM) avec le nombre d'étudiants et le prix souhaité. Ensuite, dans l'onglet 'Groupe', ajoutez un lien de meeting pour chaque groupe. Vous pouvez utiliser n'importe quelle plateforme, mais Google Meet est recommandé. Enfin, créez les groupes et associez-les aux abonnements définis. Une fois ces étapes complétées, le cours sera accessible aux étudiants."
        },

        {
          id: "4",
          categoryId: "professeurs-technique",
          question: "Comment organiser un meeting avec mes étudiants ?",
          answer:
            "Dans le formulaire de création de cours, renseignez un lien de meeting dans l'onglet 'Groupe'. Vous pouvez utiliser n'importe quelle plateforme, mais Google Meet est recommandé. Pour rejoindre le meeting, allez dans l'onglet 'Mes étudiants', où vous trouverez vos groupes et un bouton 'Rejoindre la réunion' à côté de chaque groupe."
        },
        {
          id: "5",
          categoryId: "professeurs-technique",
          question:
            "Puis-je modifier les informations d'un cours après sa création ?",
          answer:
            "Oui, vous pouvez modifier les informations d'un cours en allant dans 'Mes Cours' sur votre tableau de bord, en sélectionnant le cours concerné. N'oubliez pas d'enregistrer vos modifications."
        }
      ]
    },
    {
      id: "professeurs-payement",
      title: "Questions sur le Paiement",
      faqs: [
        {
          id: "5",
          categoryId: "professeurs-payement",
          question: "Comment vais-je être payé ?",
          answer:
            "Les paiements sont effectués par virement bancaire, MoneyGram ou Western Union, selon votre choix. À la fin de chaque mois, nous calculons le total de vos gains en fonction des abonnements de vos étudiants pour chacun de vos cours, puis nous transférons le montant via le moyen de paiement sélectionné."
        },
        {
          id: "6",
          categoryId: "professeurs-payement",
          question: "À quelle date vais-je recevoir mes paiements ?",
          answer:
            "Nous envoyons les paiements vers le 30 de chaque mois. Veuillez prévoir un délai de traitement bancaire de quelques jours selon votre établissement bancaire."
        },
        {
          id: "7",
          categoryId: "professeurs-payement",
          question:
            "Que faire si je ne reçois pas mon paiement à la fin du mois ?",
          answer:
            "Si votre paiement n’a pas été reçu d'ici le 5 du mois suivant, veuillez contacter notre support via la section 'Support' de votre tableau de bord. Assurez-vous que vos informations bancaires sont correctes pour éviter tout retard."
        },
        {
          id: "8",
          categoryId: "professeurs-payement",
          question: "Comment sont calculés mes revenus ?",
          answer:
            "Vos revenus sont basés sur le total des abonnements payés par vos étudiants sur tous vos cours. Le prix total comprend tous les abonnements actifs au cours du mois."
        }
      ]
    }
  ]

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <div className="flex flex-col items-center p-24 sm:p-40">
      <div className="flex flex-col w-full max-w-4xl">
        <div className="sm:mt-32">
          <Button
            onClick={handleGoBack}
            color="secondary"
            startIcon={
              <FuseSvgIcon>heroicons-outline:arrow-narrow-left</FuseSvgIcon>
            }
          >
            Back to Help Center
          </Button>
        </div>
        <Typography className="mt-8 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
          Questions fréquemment posées
        </Typography>

        {categories.map((category) => (
          <div key={category.id}>
            <Typography className="mt-48 sm:mt-64 text-3xl font-bold leading-tight tracking-tight">
              {category.title}
            </Typography>
            <FaqList className="w-full mt-32" list={category.faqs} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FaqPage
