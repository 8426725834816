import axios from "axios"
import { useNavigate } from "react-router-dom"

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Vérifier si c'est une erreur réseau
    if (!error.response) {
      // Rediriger vers la page d'erreur 500
      window.location.href = "/500"
    }
    // Propager l'erreur à l'appelant
    return Promise.reject(error)
  }
)
const endPoint = {
  // Login
  authLogin: "auth/login",
  // All Teachers
  allTeachers: "teachers",
  //
  allSubjects: "courses/subjects",
  //
  courseSubject: "courses/subject",
  //
  sepecialities: "courses/specialties",
  // std levels endpoint
  studyLevels: "courses/study-levels",
  // Courses
  courses: "courses",
  // subscriptions
  subscriptions: "subscriptions",
  // Teacher
  teacher: "teachers",
  // Slots
  slots: "slots",
  // Groups
  groups: "groups",
  // Student
  students: "students",
  // statistics
  statistics: "statistics",
  // users
  users: "admin/users",
  //payments
  payments: "payments"
}

export const ApiServices = {
  AuthLogin: (email: string, password: string) => {
    return axiosInstance.post(endPoint.authLogin, { email, password })
  },

  getAllTeachers: (token: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.get(endPoint.allTeachers, { headers })
  },

  getAllSubjects: () => {
    return axiosInstance.get(endPoint.allSubjects)
  },

  storeSubject: (token: string, name: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }
    return axiosInstance.post(endPoint.courseSubject, { name }, { headers })
  },

  getSubjectById: (id: string) => {
    return axiosInstance.get(endPoint.courseSubject + "/" + id)
  },

  updateSubjectById: (token: string, id: string, name: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }
    return axiosInstance.put(
      endPoint.courseSubject + "/" + id,
      { name },
      { headers }
    )
  },

  removeSubjectById: (token: string, id: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.delete(`${endPoint.courseSubject}/${id}`, { headers })
  },
  // Specilities Crud
  getAllSepecialities: () => {
    return axiosInstance.get(endPoint.sepecialities)
  },

  getSpecialitieBtId: (id: string) => {
    return axiosInstance.get(`${endPoint.sepecialities}/${id}`)
  },

  removeSpecialitieById: (token: string, id: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.delete(`${endPoint.sepecialities}/${id}`, { headers })
  },

  storeSpecialitie: (token: string, name: string, studyLevelIds: number[]) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.post(
      endPoint.sepecialities,
      { name, studyLevelIds }, // Mise à jour : utilisez un tableau pour studyLevelIds
      { headers }
    )
  },

  updateSpecialitieById: (
    token: string,
    id: string,
    name: string,
    studyLevelId: number
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.put(
      endPoint.sepecialities + "/" + id,
      { name: name, studyLevelIds: studyLevelId },
      { headers }
    )
  },

  // Study Levels
  getAllStudyLevels: () => {
    return axiosInstance.get(endPoint.studyLevels)
  },

  getStudyLevelById: (id: string) => {
    return axiosInstance.get(`${endPoint.studyLevels}/${id}`)
  },

  storeStudyLevel: (token: string, name: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.post(endPoint.studyLevels, { name }, { headers })
  },

  updateStudyLevelById: (token: string, id: string, name: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }
    return axiosInstance.put(
      endPoint.studyLevels + "/" + id,
      { name },
      { headers }
    )
  },

  removeStudyLevelById: (token: string, id: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.delete(`${endPoint.studyLevels}/${id}`, { headers })
  },
  // Courses

  getAllCourses: () => {
    return axiosInstance.get(`${endPoint.courses}`)
  },

  getCourseById: (id: number) => {
    return axiosInstance.get(`${endPoint.courses}/${id}`)
  },

  getAllCoursesByTeacherId: (id: number) => {
    return axiosInstance.get(`${endPoint.teacher}/${id}/courses`)
  },

  storeCourse: (token: string, data, teacherId: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    const {
      title,
      description,
      content,
      studyLevelId,
      subjectId,
      specialtyIds,
      photos,
      isLanguageCourse
    } = data

    const formData = new FormData()
    formData.append("title", title)
    formData.append("description", description)
    formData.append("content", content)
    formData.append("studyLevelId", String(studyLevelId))
    formData.append("subjectId", String(subjectId))
    formData.append("specialtyIds", String(specialtyIds))
    formData.append("photos", photos[0])
    formData.append("isLanguageCourse", isLanguageCourse)

    return axiosInstance.post(`${endPoint.courses}/${teacherId}`, formData, {
      headers
    })
  },

  updateCourseById: (token: string, id: number, data) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    const {
      title,
      description,
      content,
      studyLevelId,
      subjectId,
      specialtyIds,
      photos,
      isLanguageCourse
    } = data

    const formData = new FormData()
    formData.append("title", title)
    formData.append("description", description)
    formData.append("content", content)
    formData.append("studyLevelId", String(studyLevelId))
    formData.append("subjectId", String(subjectId))
    formData.append("specialtyIds", String(specialtyIds))
    formData.append("isLanguageCourse", isLanguageCourse)

    if (typeof photos === "object") {
      formData.append("photos", photos)
    }

    return axiosInstance.put(`${endPoint.courses}/${id}`, formData, {
      headers
    })
  },

  //

  storeSubscription: (token: string, data) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }
    return axiosInstance.post(`${endPoint.subscriptions}`, data, {
      headers
    })
  },

  updateSubscriptionById: (token: string, id: number, data) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.put(`${endPoint.subscriptions}/${id}`, data, {
      headers
    })
  },

  StoreGroupPlusSlot: (token: string, id: number, data) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.post(`${endPoint.allTeachers}/${id}/groups`, data, {
      headers
    })
  },

  updateGroupPlusSlot: (token: string, groupId: number, data) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    }

    // Construire l'URL pour l'endpoint avec groupId
    const url = `${endPoint.groups}/update-group/${groupId}`

    // Envoyer la requête PUT avec les données de mise à jour
    return axiosInstance.put(url, data, { headers })
  },

  //`${endPoint.groups}/update-group/${groupId}`,

  getAllSlotsOfTeacher: (token: string, id: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }
    return axiosInstance.get(`${endPoint.allTeachers}/${id}/groups`, {
      headers
    })
  },

  getSubscriptionByCourseId: (token: string, id: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.get(`${endPoint.courses}/${id}/subscriptions`, {
      headers
    })
  },

  getSlotsByCourseId: (token: string, id: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }
    return axiosInstance.get(`${endPoint.courses}/${id}/groups`, { headers })
  },

  removeCourseById: (token: string, id: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.delete(`${endPoint.courses}/${id}`, { headers })
  },
  // Student&Groups

  getAllStudents: () => {
    return axiosInstance.get(endPoint.students)
  },

  getAllGroupsByTecherId: (id: number) => {
    return axiosInstance(`${endPoint.teacher}/${id}/groups`)
  },

  getAllGroupsByStudentId: (id: number) => {
    return axiosInstance.get(`${endPoint.students}/${id}/groups`)
  },

  // Course Publish Status
  coursePublishStatus: (token: string, id: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }
    return axiosInstance.put(`${endPoint.courses}/${id}/complete`, { headers })
  },

  removeGroupByTeacherAndGroupId: (
    token: string,
    teacherId: number,
    groupId: number
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.delete(
      `${endPoint.teacher}/${teacherId}/groups/${groupId}`,
      { headers }
    )
  },

  getStudentListByGroupId: (token: string, groudId: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.get(
      `${endPoint.groups}/${groudId}/${endPoint.students}`,
      { headers }
    )
  },

  getPaymentsByTeacherId: (token: string, teacherId: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.get(
      `${endPoint.teacher}/${teacherId}/${endPoint.courses}/payment-statuses`,
      { headers }
    )
  },

  getAllPayments: (
    token: string,
    teacherId: number,
    month?: string,
    year?: string
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    const params = {
      month,
      year
    }

    return axiosInstance.get(
      `${endPoint.teacher}/all/courses/payment-statuses`,
      { headers, params }
    )
  },

  getAllTeacherPayments: (token: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }
    return axiosInstance.get(`${endPoint.payments}/teacher-payments`, {
      headers
    })
  },

  getStudentPayments: (
    token: string,
    studentId: number,
    month?: string,
    year?: string
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    const params = {
      month,
      year
    }

    return axiosInstance.get(
      `${endPoint.students}/${studentId}/payment-statuses`,
      {
        headers,
        params
      }
    )
  },

  handlePaymentValidate: (token: string, id: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.post(`/enrollments/validate-payment/${id}`, null, {
      headers
    })
  },

  handleCancelSubscribtionRequest: (token: string, subscriptionId: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.put(
      `/enrollments/cancel-request/${subscriptionId}`,
      null,
      {
        headers
      }
    )
  },

  // Refund confirm /enrollments/confirm-cancellation/{enrollmentId}
  handleRefundConfirm: (token: string, enrollmentId: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.put(
      `/enrollments/confirm-cancellation/${enrollmentId}`,
      null,
      {
        headers
      }
    )
  },

  getPendingCancellations: (token: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.get(`/enrollments/pending-cancellations`, {
      headers
    })
  },

  // Support

  postSupportMessage: (token: string, data) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.post(`/contact/support`, data, { headers })
  },

  // Dashboards
  getTeacherStatistics: (token: string, teacherId: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.get(`${endPoint.statistics}/teacher`, {
      headers
    })
  },

  getAdminStatistics: (token: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.get(`${endPoint.statistics}/admin`, {
      headers
    })
  },

  getStudentStatistics: (token: string, StudentId: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.get(`${endPoint.statistics}/student/${StudentId}`, {
      headers
    })
  },
  // Profile
  getTeacherDetailsById: (id: number) => {
    return axiosInstance.get(`${endPoint.teacher}/${id}`)
  },

  updateTeacherById: (token: string, id: number, data) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    }

    const formData = new FormData()
    formData.append("firstName", data.firstName)
    formData.append("lastName", data.lastName)
    formData.append("email", data.email)
    formData.append("phone", data.phone)
    formData.append("address", data.address)
    formData.append("city", data.city)
    formData.append("gender", data.gender)
    const birthDate = new Date(data.birthDate)
    const formattedBirthDate = `${String(birthDate.getDate()).padStart(
      2,
      "0"
    )}/${String(birthDate.getMonth() + 1).padStart(
      2,
      "0"
    )}/${birthDate.getFullYear()}`
    formData.append("birthDate", formattedBirthDate)

    formData.append("aboutMe", data.aboutMe)
    if (data.userImage.length !== 0) {
      formData.append("photos", data.userImage)
    }

    return axiosInstance.put(`${endPoint.teacher}/${id}`, formData, {
      headers
    })
  },

  updateTeacherPassword: (
    token: string,
    email: string,
    newPassword: string
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    const data = {
      email: email,
      newPassword: newPassword
    }

    return axiosInstance.put(`${endPoint.teacher}/reset-password`, data, {
      headers
    })
  },

  // Student Profile
  getStudentDetailsById: (id: number) => {
    return axiosInstance.get(`${endPoint.students}/${id}`)
  },

  updateStudentById: (token: string, id: number, data) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    }

    const formData = new FormData()
    formData.append("firstName", data.firstName)
    formData.append("lastName", data.lastName)
    formData.append("email", data.email)
    formData.append("phone", data.phone)
    formData.append("address", data.address)
    formData.append("city", data.city)
    formData.append("studyLevelId", data.studyLevelId)
    formData.append("specialtyId", data.specialtyId)

    const date = new Date(data.birthDate)
    const formattedBirthDate = `${date.getFullYear()}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`

    formData.append("birthDate", formattedBirthDate)
    formData.append("gender", data.gender)
    if (data.userImage.length !== 0) {
      formData.append("photos", data.userImage)
    }

    return axiosInstance.put(`${endPoint.students}/${id}`, formData, {
      headers
    })
  },

  updateStudentPassword: (
    token: string,
    email: string,
    newPassword: string
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    const data = {
      email: email,
      newPassword: newPassword
    }

    return axiosInstance.put(`${endPoint.students}/reset-password`, data, {
      headers
    })
  },

  // Admin Profile
  getAdminDetailsById: (token: string, id: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.get(`admin/users/${id}`, { headers })
  },

  updateAdminById: (token: string, id: number, data) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    }

    const formData = new FormData()
    formData.append("firstName", data.firstName)
    formData.append("lastName", data.lastName)
    formData.append("email", data.email)
    formData.append("phone", data.phone)
    formData.append("city", data.city)
    if (data.userImage.length !== 0) {
      formData.append("photos", data.userImage)
    }

    return axiosInstance.put(`admin/${id}`, formData, {
      headers
    })
  },

  updateAdminPassword: (token: string, email: string, newPassword: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    const data = {
      email: email,
      newPassword: newPassword
    }

    return axiosInstance.put(`${endPoint.students}/reset-password`, data, {
      headers
    })
  },

  getAllUsers: (token: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.get(endPoint.users, { headers })
  },

  handleUserStatus: (token: string, id: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.put(`${endPoint.users}/enable/${id}`, null, {
      headers
    })
  },

  handleUserStatusDeactive: (token: string, id: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.put(`${endPoint.users}/disable/${id}`, null, {
      headers
    })
  },

  removeUserById: (token: string, id: string) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.delete(`${endPoint.users}/${id}`, { headers })
  },

  getUserById: (token: string, id: number) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return axiosInstance.get(`${endPoint.users}/${id}`, { headers })
  },

  updateUserById: (token: string, id: number, data) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    const formData = new FormData()
    formData.append("firstName", data.firstName)
    formData.append("lastName", data.lastName)
    formData.append("email", data.email)
    formData.append("phone", data.phone)
    formData.append("address", data.address)
    formData.append("city", data.city)

    const date = new Date(data.birthDate)
    const formattedBirthDate = `${date.getFullYear()}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`

    formData.append("birthDate", formattedBirthDate)
    formData.append("gender", data.gender)

    if (data.userImage.length !== 0) {
      formData.append("photos", data.userImage)
    }

    return axiosInstance.put(`${endPoint.users}/${id}`, formData, { headers })
  }
}
