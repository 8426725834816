// TutorialPage.js
import React from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"
import Container from "@mui/material/Container"

// URLs des captures d'écran
const images = {
  dashboard:
    "https://cpp-photo-bucket-prod-ok.s3.eu-north-1.amazonaws.com/iScreen+Shoter+-+Google+Chrome+-+241103105359.png",
  addCourse:
    "https://cpp-photo-bucket-prod-ok.s3.eu-north-1.amazonaws.com/iScreen+Shoter+-+Google+Chrome+-+241103105455.png",
  addCourseStep1:
    "https://cpp-photo-bucket-prod-ok.s3.eu-north-1.amazonaws.com/iScreen+Shoter+-+Google+Chrome+-+241103180614.png",
  courseList:
    "https://cpp-photo-bucket-prod-ok.s3.eu-north-1.amazonaws.com/iScreen+Shoter+-+Google+Chrome+-+241103105523.png",
  addSubscription:
    "https://cpp-photo-bucket-prod-ok.s3.eu-north-1.amazonaws.com/iScreen+Shoter+-+Google+Chrome+-+241103180701.png",
  addGroup:
    "https://cpp-photo-bucket-prod-ok.s3.eu-north-1.amazonaws.com/iScreen+Shoter+-+Google+Chrome+-+241103180750.png",
  googleMeet1:
    "https://cpp-photo-bucket-prod-ok.s3.eu-north-1.amazonaws.com/iScreen+Shoter+-+Google+Chrome+-+241103180810.png",
  googleMeet2:
    "https://cpp-photo-bucket-prod-ok.s3.eu-north-1.amazonaws.com/iScreen+Shoter+-+Google+Chrome+-+241103180959.png",
  googleMeet3:
    "https://cpp-photo-bucket-prod-ok.s3.eu-north-1.amazonaws.com/iScreen+Shoter+-+Google+Chrome+-+241103181031.png"
}

const TutorialPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Guide d'utilisation de l'application
      </Typography>
      <Typography variant="body1" paragraph>
        Bienvenue dans le tutoriel pour apprendre à utiliser notre application.
        Suivez les étapes ci-dessous pour découvrir comment naviguer et utiliser
        les fonctionnalités principales.
      </Typography>

      {/* Étape 1: Accéder au tableau de bord et aller dans "Mes cours" */}
      <Card sx={{ my: 4, p: 2, borderRadius: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Étape 1 : Accéder au tableau de bord et aller dans "Mes cours"
          </Typography>
          <Typography variant="body1" paragraph>
            Une fois connecté, accédez au tableau de bord de l'application.
            Ensuite, dans le menu, sélectionnez l'option "Mes cours".
          </Typography>
          <CardMedia
            component="img"
            height="300"
            image={images.dashboard}
            alt="Tableau de bord"
            sx={{ borderRadius: 2, my: 2 }}
          />
          <Typography variant="body1">
            Dans "Mes cours", vous trouverez la liste des cours que vous avez
            créés ainsi qu'un bouton pour ajouter de nouveaux cours.
          </Typography>
        </CardContent>
      </Card>

      {/* Étape 2: Créer un nouveau cours */}
      <Card sx={{ my: 4, p: 2, borderRadius: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Étape 2 : Créer un nouveau cours
          </Typography>
          <Typography variant="body1" paragraph>
            Dans la section "Mes cours", cliquez sur le bouton "Nouveau cours"
            pour lancer la création d'un cours.
          </Typography>
          <CardMedia
            component="img"
            height="300"
            image={images.addCourse}
            alt="Créer un cours"
            sx={{ borderRadius: 2, my: 2 }}
          />
          <Typography variant="body1">
            Dans le premier onglet du formulaire, renseignez les informations de
            base du cours, telles que le titre, la description, et les
            ressources associées.
          </Typography>
          <CardMedia
            component="img"
            height="300"
            image={images.addCourseStep1}
            alt="Créer un cours"
            sx={{ borderRadius: 2, my: 2 }}
          />
        </CardContent>
      </Card>

      {/* Étape 3: Créer les abonnements */}
      <Card sx={{ my: 4, p: 2, borderRadius: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Étape 3 : Créer les abonnements (SILVER, GOLD, PLATINUM)
          </Typography>
          <Typography variant="body1" paragraph>
            Dans le deuxième onglet du formulaire de création de cours, ajoutez
            les abonnements nécessaires. Vous pouvez créer un abonnement SILVER,
            GOLD et/ou PLATINUM en définissant le nombre maximal d'étudiants et
            le prix pour chaque abonnement.
          </Typography>
          <CardMedia
            component="img"
            height="300"
            image={images.addSubscription}
            alt="Créer un abonnement"
            sx={{ borderRadius: 2, my: 2 }}
          />
          <Typography variant="body1">
            Chaque abonnement permet aux étudiants d'accéder au cours selon le
            niveau sélectionné. Cliquez sur "Sauvegarder" une fois que les
            abonnements sont configurés.
          </Typography>
        </CardContent>
      </Card>

      {/* Étape 4: Créer les groupes et ajouter un lien de meeting */}
      <Card sx={{ my: 4, p: 2, borderRadius: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Étape 4 : Créer les groupes et ajouter un lien de la réunion
          </Typography>
          <Typography variant="body1" paragraph>
            Dans le troisième onglet du formulaire, vous pouvez créer des
            groupes d'étudiants. Liez chaque groupe à un abonnement spécifique
            (SILVER, GOLD ou PLATINUM) pour gérer les étudiants inscrits en
            fonction de l'abonnement.
          </Typography>
          <CardMedia
            component="img"
            height="300"
            image={images.addGroup}
            alt="Créer un groupe"
            sx={{ borderRadius: 2, my: 2 }}
          />
          <Typography variant="body1">
            Ajoutez également un lien de la réunion pour chaque groupe. Vous
            pouvez utiliser Google Meet ou une autre plateforme. Ce lien
            permettra aux étudiants de rejoindre le cours en ligne.
          </Typography>
          <CardMedia
            component="img"
            height="300"
            image={images.googleMeet1}
            alt="Créer un groupe"
            sx={{ borderRadius: 2, my: 2 }}
          />
          Ensuite cliquez sur "Démarrer une réunion instantanéé"
          <CardMedia
            component="img"
            height="300"
            image={images.googleMeet2}
            alt="Créer un groupe"
            sx={{ borderRadius: 2, my: 2 }}
          />
          <CardMedia
            component="img"
            height="300"
            image={images.googleMeet3}
            alt="Créer un groupe"
            sx={{ borderRadius: 2, my: 2 }}
          />
          Ajoutez le lien de réunion pour chaque groupe
          <CardMedia
            component="img"
            height="300"
            image={images.addGroup}
            alt="Créer un groupe"
            sx={{ borderRadius: 2, my: 2 }}
          />
        </CardContent>
      </Card>

      {/* Conclusion */}
      <Box sx={{ mt: 5, textAlign: "center" }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Conclusion
        </Typography>
        <Typography variant="body1">
          Félicitations ! Vous savez maintenant comment créer un cours,
          configurer les abonnements et les groupes, et ajouter des liens de
          réunions dans notre application. Pour toute question, n'hésitez pas à
          contacter notre support.
        </Typography>
      </Box>
    </Container>
  )
}

export default TutorialPage
